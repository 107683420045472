import { providerResponseSMS } from '@/shared/filters/ProviderResponseSMS'
import SMSFilterCriteria from '@/shared/models/criteria/smsfiltercriteria'
import SmsRepository from '@/shared/http/repositories/socialProject/sms'
import BreadCrumb from '@/components/layout/BreadCrumb/breadCrumb.vue'
import { customDateFilter } from '@/shared/filters/ToCustomDate'
import { phoneFilterBrasil } from '@/shared/filters/ToPhoneFormatted'
import { loading, toast } from '@/shared/utils/UIHelper'
import { IsSuccessCode } from '@/shared/utils/API'
import Roles from '@/shared/mixins/hasRoles'
import moment from 'moment'

export default {
  name: 'SmsLogs',

  components: {
    [BreadCrumb.name]: BreadCrumb
  },

  mixins: [Roles],

	data: () => ({
    criteria: new SMSFilterCriteria(),
    selected: [],
    showDrawer: false,
    monthlySpendLimit: 0,
    totallySpended: 0,
    totallySpendSuccess: 0,
    totallySpendFailure: 0,
    remainInDolar: 80,
    totallyRegisterSuccess: 0,
    totallyRegisterFailure: 0,
    totallySMSSuccessSended: 0,
    totallySMSFailureSended: 0,
    costPerSms: '0.023',
    emptyTextSuccess: 'Não há SMS entregues para visualizar',
    emptyTextFailure: 'Não há SMS com falhas para visualizar',
    successLogsItems: [],
    failureLogsItems: [],
    phonesFailure: [],
    phonesSuccess: [],
    persons: [],
    dateStartFormatted: null,
    dateEndFormatted: null,
    fieldsTableSuccess: [
      {
        key: 'notification.timestamp',
        label: 'DATA DO SMS',
        class: 'left-header-border text-center',
        sortable: true,
        formatter: (value) => {
          if (!value) return

          const dateSMS = value
          const dateSMSFormatBRL = moment(dateSMS).subtract(3,'hours').format("DD/MM/YYYY HH:mm")
          return dateSMSFormatBRL
        }
      },
      {
        key: 'delivery.destination',
        label: 'NÚMERO DESTINADO',
        class: 'middle-header text-center',
        formatter: phoneFilterBrasil
      },
      {
        key: 'origin',
        label: 'ORIGEM',
        class: 'middle-header text-center',
        formatter: (value, key, item) => {
          if (!item) return 'N/D'

          const isStudent = item.studentId ? item.studentId : null
          const isWaitingList = item.waitingListId ? item.waitingListId : null

          if (isStudent) return 'isStudent'
          if (isWaitingList) return 'isWaitingList'

          return 'N/D'
        }
      },
      {
        key: 'name',
        label: 'NOME DESTINADO',
        class: 'middle-header text-center',
        formatter: (value, key, item) => {
          const name = item.name ? item.name : 'N/D'
          return name
        }
      },
      {
        key: 'delivery.providerResponse',
        label: 'RESULTADO SMS',
        class: 'right-header-border text-center',
        formatter: providerResponseSMS
      }
    ],
    fieldsTableFailure: [
      {
        key: 'notification.timestamp',
        label: 'DATA DO SMS',
        class: 'left-header-border text-center',
        sortable: true,
        formatter: (value) => {
          if (!value) return

          const dateSMS = value
          const dateSMSFormatBRL = moment(dateSMS).subtract(3,'hours').format("DD/MM/YYYY HH:mm")
          return dateSMSFormatBRL
        }
      },
      {
        key: 'delivery.destination',
        label: 'NÚMERO DESTINADO',
        class: 'middle-header text-center',
        formatter: phoneFilterBrasil
      },
      {
        key: 'origin',
        label: 'ORIGEM',
        class: 'middle-header text-center',
        formatter: (value, key, item) => {
          if (!item) return 'N/D'

          const isStudent = item.studentId ? item.studentId : null
          const isWaitingList = item.waitingListId ? item.waitingListId : null

          if (isStudent) return 'isStudent'
          if (isWaitingList) return 'isWaitingList'

          return 'N/D'
        }
      },
      {
        key: 'name',
        label: 'NOME DESTINADO',
        class: 'middle-header text-center',
        formatter: (value, key, item) => {
          const name = item.name ? item.name : 'N/D'
          return name
        }
      },
      {
        key: 'delivery.providerResponse',
        label: 'RESULTADO SMS',
        class: 'right-header-border text-center',
        formatter: providerResponseSMS
      }
    ],
	}),

	created() { 
    this.getSMSAttributes()
    this.checkPermission()
  },

	computed: {
    breadCrumb() {
      return {
        breadcrumbItems: [
          {
            text: 'Dashboard',
            href: this.$router.resolve({ name: 'Dashboard' }).href
          },
          {
            text: 'Logs de SMS',
            active: true
          }
        ]
      }
    },

    disableDateBtn() {
      if (!this.criteria.dateStart && !this.criteria.dateEnd) {
        return true
      }
      return false
    }
  },

	methods: {

    async onSearch() {
      if (!this.criteria.dateStart || !this.criteria.dateEnd) return toast.warning('Prencha a data inicial e final.', 'RELATÓRIO DE SMS')
      
      if(this.criteria.dateStart && this.criteria.dateEnd) {
        const start = moment(this.criteria.dateStart)
        const end = moment(this.criteria.dateEnd)
        if (start.isAfter(end)) return toast.warning('Data inicial deve ser menor ou igual a data final.', 'AVISO')
      }

      await this.getSMSLogsSuccess()
      await this.getSMSLogsFailure()
    },

		getSMSAttributes() {
      loading.push()
      SmsRepository.GetSMSAttributes()
      .then(res => {
        if (!IsSuccessCode(res)) return Promise.reject()

          const response = res.data.data
					this.monthlySpendLimit = response.attributes.MonthlySpendLimit

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao pegar limite mensal de SMS', 'ERRO')
        })
    },

		async getSMSLogsSuccess() {
      this.totallySpendSuccess = 0
      this.successLogsItems = []
      this.totallyRegisterSuccess = 0
      this.totallySMSSuccessSended = 0
      this.phonesSuccess = []
      this.persons = []

      loading.push()
      await SmsRepository.GetSMSLogsSuccess(this.criteria)
      .then(async res => {
        if (!IsSuccessCode(res)) return Promise.reject()
          const eventsResponse = res.data.result
          if (eventsResponse.length == 0) {
            this.remainInDolar = 80
            this.totallySpended = 0
            loading.pop()
            return Promise.resolve()
          }

          const items = []
          const logsSuccess = eventsResponse.map(x => x.message)
          this.totallyRegisterSuccess = eventsResponse.length

          logsSuccess.forEach(el => {
            const parseSuccess = JSON.parse(el)
            this.totallySpendSuccess += parseSuccess.delivery.priceInUSD
            this.totallySMSSuccessSended += parseSuccess.delivery.numberOfMessageParts
            this.phonesSuccess.push(this.clearPhoneNumber(parseSuccess.delivery.destination))
            items.push(parseSuccess)
          })

          await this.onGetPersonByPhoneNumber(this.phonesSuccess)
          await this.findAndSetPerson(items, true)
            
          
        loading.pop()
        return Promise.resolve()
      })
      .catch(() => {
        loading.pop()
        toast.error('Erro ao pegar LOGS de SMS - ENTREGUES', 'ERRO')
      })
    },

    async findAndSetPerson(items, isSuccessLogs) {
      for (let i = 0; i < items.length; i++) {
        const phoneFormatted = this.clearPhoneNumber(items[i].delivery.destination)
        const theSamePerson = this.persons.find(x => phoneFormatted == x.phoneNumber)

       if (!theSamePerson) {
          items[i].name = 'N/D'
          items[i].nameResponsible = "N/D"
          items[i].studentId = null
          items[i].waitingListId = null
        } else {
          items[i].name = theSamePerson.name
          items[i].nameResponsible = theSamePerson.nameResponsible
          items[i].studentId = theSamePerson.studentId
          items[i].waitingListId = theSamePerson.waitingListId
        }
      }

      if (isSuccessLogs) {
        return this.successLogsItems = items.sort((a, b) => 
          moment(b.notification.timestamp) - moment(a.notification.timestamp))
      }
      
      return this.failureLogsItems = items.sort((a, b) =>
        moment(b.notification.timestamp) - moment(a.notification.timestamp))
    },

    async getSMSLogsFailure() {
      this.totallySpendFailure = 0
      this.failureLogsItems = []
      this.totallyRegisterFailure = 0
      this.totallySMSFailureSended = 0
      this.phonesFailure = []
      this.persons = []

      loading.push()
      await SmsRepository.GetSMSLogsFailure(this.criteria)
      .then(async res => {
        if (!IsSuccessCode(res)) return Promise.reject()
        const eventsResponse = res.data.result
        if (eventsResponse.length == 0) {
          this.remainInDolar = 80
          this.totallySpended = 0
          loading.pop()
          return Promise.resolve()
        }

        const items = []
        const logsFailure = eventsResponse.map(x => x.message)
        this.totallyRegisterFailure = eventsResponse.length

        logsFailure.forEach(el => {
          const parseFailure = JSON.parse(el)
          this.totallySpendFailure += parseFailure.delivery.priceInUSD
          this.totallySMSFailureSended += parseFailure.delivery.numberOfMessageParts
          this.phonesFailure.push(this.clearPhoneNumber(parseFailure.delivery.destination))
          items.push(parseFailure)
        })

        await this.onGetPersonByPhoneNumber(this.phonesFailure)
        await this.findAndSetPerson(items, false)

        loading.pop()
        return Promise.resolve()
      })
      .catch(() => {
        loading.pop()
        toast.error('Erro ao pegar LOGS de SMS - FALHA', 'ERRO')
      })
    },

    async onGetPersonByPhoneNumber(phones) {
      loading.push()
      await SmsRepository.GetPersonByPhoneNumber(phones = {phones})
      .then(async res => {
        if (!IsSuccessCode(res)) return Promise.reject()

          this.persons = res.data.data

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao pegar os nomes', 'ERRO')
        })
    },

    clearPhoneNumber(phone) {
      if (!phone) return

      const phoneFormatted = phone.replace('+55', '')
      return phoneFormatted
    },

    formatTotally(value) {
      if (!value) return 0

      const totally = this.totallySpendSuccess + this.totallySpendFailure
      const totallyFormatted = Number.parseFloat(totally).toFixed(3)
      this.totallySpended = totallyFormatted

      
      const totallyRemain = this.monthlySpendLimit - this.totallySpended
      const totallyReaminFormatted = Number.parseFloat(totallyRemain).toFixed(3)
      this.remainInDolar = totallyReaminFormatted

      const valueFormatted = Number.parseFloat(value).toFixed(3)
      return valueFormatted
    },

    checkPermission() {
      loading.push()

      setTimeout(() => {
        const permission = this.hasRole(this.roles.R_SMSLOGS)
        if (permission) {
          this.getCurrentSMSLogs()
          loading.pop()
          return
        }

        this.emptyTextSuccess = 'VOCÊ NÃO TEM PERMISSÃO PARA VISUALIZAR ESTA TABELA'
        this.emptyTextFailure = 'VOCÊ NÃO TEM PERMISSÃO PARA VISUALIZAR ESTA TABELA'
        loading.pop()
      }, 2500)
    },

    onShowDrawer(item) {
      if (!item) return
      this.selected = item
      this.showDrawer = true
    },

    onCloseDrawer() {
      this.showDrawer = false
      this.selected = []
    },

    async getCurrentSMSLogs() {
      const currentDate = moment().format("YYYY/MM/DD")
      this.criteria.dateStart = currentDate
      this.criteria.dateEnd = currentDate

      await this.getSMSLogsSuccess()
      await this.getSMSLogsFailure()
    },

    cutName(name) {
      if (name == 'N/D') return 'N/D'

      const fullName = name
      const firstName = fullName.split(" ")[0]
      const fullNameLength = fullName.split(" ").length
      const lastName = fullName.split(" ")[fullNameLength-1] 
      const shortName = firstName + ' ' + lastName

      return shortName
    },

    onContextDateStart(ctx) { this.dateStartFormatted = ctx.selectedFormatted },

    formatDateStart(event) { this.criteria.dateStart = customDateFilter(event.currentTarget.value) },

    onContextDateEnd(ctx) { this.dateEndFormatted = ctx.selectedFormatted },

    formatDateEnd(event) { this.criteria.dateEnd = customDateFilter(event.currentTarget.value) },
	}
}