import Vue from  'vue'

// eslint-disable-next-line
export const providerResponseSMS = (value) => {
  if (!value) return '-'

  if (value == 'Message has been accepted by phone carrier' || value == 'Message has been accepted by phone') {
    return 'Mensagem aceita e entregue pela operadora'
  }

  if (value == 'Unknown error attempting to reach phone') {
    return 'Erro ao entrar em contato com o telefone'
  }

  if (value == 'No quote left for account' || value == 'Daily message quota exceeded' || value == 'Maximum sending rate exceeded') {
    return 'A cota de SMS foi batida'
  }

  return 'Mensagem Desconhecida'
}

Vue.filter('ProviderResponseSMS', providerResponseSMS)